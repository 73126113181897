import React from 'react'

import { useDimensions } from 'src/ui/dimensions'
import { RootLayout } from 'src/layouts'
import { HBox } from 'src/ui/layout'
import { SaintPetersburg as Hero, Reasons } from 'src/features/saint-petersburg'

import { Meta } from 'src/features/seo'

import { graphql } from 'gatsby'
import { Query } from 'hr/graphql-types'

type Props = {} & { data: Query }

const SaintPetersburg = ({ data }: Props) => {
  const images = data.allFile.edges.reduce<TImages>((acc, img) => {
    return {
      ...acc,
      [img.node.name]: img.node.childImageSharp?.fluid as TFluidObject,
    }
  }, {})
  const { isMobile } = useDimensions()
  return (
    <RootLayout>
      <Meta
        title="Работа в Санкт-Петербурге в IT — KODE"
        description="Ищете работу в IT? Компания KODE приглашает тебя стать частью дружной команды в калининградской компании KODE ✔ Ищем UX-дизайнеров, аналитиков, QA, разработчиков"
      />

      {isMobile ? <HBox /> : null}
      <Hero images={images} />
      <Reasons images={images} />
      <HBox height={isMobile ? 48 : 80} />
    </RootLayout>
  )
}

export const query = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/saint-petersburg/i" } }) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            fluid(maxWidth: 1900) {
              srcSet
              base64
              src
              aspectRatio
            }
          }
        }
      }
    }
  }
`
export default SaintPetersburg
