import React from 'react'

import { HBox } from 'src/ui/layout'
import { HeroDesktopSecondary } from 'src/ui/organisms'
import { Body } from 'src/ui/typography'

type Props = {
  images: TImages
}

export const SaintPetersburg = ({ images }: Props) => (
  <HeroDesktopSecondary
    contentPosition="bottom-left"
    background={images['city']}
    title="Северная столица"
    adornment
    titleSize="h2"
    titleAs="h1"
    objectPosition="top center"
  >
    <HBox height={24} />
    <Body
      style={{
        maxWidth: '640px',
      }}
    >
      Санкт-Петербург называют культурной столицей, городом&nbsp;проспектов и
      мостов, северной Венецией и городом на Неве.&nbsp;Кроме величественной
      архитектуры и уникальной истории,&nbsp;у него есть ещё одно главное
      преимущество — это один&nbsp; из самых развивающихся IT-кластеров России.
    </Body>
  </HeroDesktopSecondary>
)
