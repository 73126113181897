import React from 'react'
import { useDimensions } from 'src/ui/dimensions'

import { Reasons as Desc } from './desk'
import { Reasons as Mob } from './mob'

type Props = {
  images: TImages
}
export const Reasons = ({ images }: Props) => {
  const { isMobile } = useDimensions()
  return isMobile ? <Mob images={images} /> : <Desc images={images} />
}
