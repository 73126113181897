import React from 'react'
import { styled } from 'src/ui/theme'
import { HBox, Wrapper } from 'src/ui/layout'
import { Image } from 'src/ui/atoms'
import { HeroDesktopSecondary } from 'src/ui/organisms'

import { Body, Header } from 'src/ui/typography'
import { Link } from 'gatsby'

const Row = styled.div`
  display: grid;
  grid-template-columns: 43.9% 52.3%;
  gap: 3.8%;
`

const Col = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
`

const Heading = styled.div`
  position: relative;
  min-height: 58px;
`

const StyledBody = styled(Body)`
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`

type Props = {
  images: TImages
}

export const Reasons = ({ images }: Props) => {
  return (
    <>
      <HBox height={74} />
      <Wrapper>
        <Row>
          <Col>
            <Image height={360} fluid={images['center']} />
          </Col>

          <Col>
            <Heading>
              <Header size="h4" as="h2">
                В самом центре культурного наследия
              </Header>
            </Heading>
            <HBox />
            <StyledBody>
              В окружении Фонтанки, Невского проспекта и главных туристических
              локаций, мы основали офис KODE в самом центре Петербурга — на
              улице Марата.
            </StyledBody>
            <StyledBody>
              Во время перерыва можно насладиться неспешной прогулкой до Невы и
              обратно, а вечером — удобно добраться до дома на метро. В шаговой
              доступности три ветки — Маяковская, Достоевская и Владимирская.
            </StyledBody>
            <HBox height={24} />
          </Col>
        </Row>

        <Row>
          <Col>
            <HBox height={40} />
            <Heading>
              <Header size="h4" as="h2">
                Единые технологические подходы
              </Header>
            </Heading>
            <HBox />
            <StyledBody>
              Сохранили принципы работы, которые выстраиваем в Калининграде, и
              перевезли накопленный опыт в питерский офис.
            </StyledBody>
            <StyledBody>
              Передовые технологии и подходы, highload-проекты с миллионными DAU
              и высокое качество разработки — ключевые преимущества KODE во всех
              городах. Развиваем командные и&nbsp;индивидуальные инициативы.
            </StyledBody>
            <HBox height={24} />
          </Col>

          <Col>
            <Image
              height={350}
              fluid={images['tecnology']}
              objectPosition="bottom center"
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Image height={350} fluid={images['culture']} />
          </Col>

          <Col>
            <HBox height={40} />
            <Heading>
              <Header size="h4" as="h2">
                Развитая корпоративная культура
              </Header>
            </Heading>
            <HBox />
            <StyledBody>
              Вне работы проводим внутренние митапы для развития скиллов,
              устраиваем тимбилдинги для сплочения команды, а по четвергам
              играем в настолки, что уже&nbsp;стало любимой традицией.
            </StyledBody>
            <StyledBody>
              Дважды в год собираемся большой командой KODE и отправляемся
              в&nbsp;совместное путешествие.
            </StyledBody>
            <HBox height={24} />
          </Col>
        </Row>

        <Row>
          <Col>
            <HBox height={54} />
            <Heading>
              <Header size="h4" as="h2">
                Комфортное лофт-пространство
              </Header>
            </Heading>
            <HBox />
            <StyledBody>
              Просторный опенспейс с игровыми креслами, большими мониторами и
              кофе-поинтами. Как говорят сами сотрудники, работать здесь намного
              удобнее, чем дома. К тому же офис открыт&nbsp;в любое время.
            </StyledBody>
            <HBox height={40} />
          </Col>

          <Col>
            <Image height={350} fluid={images['space']} />
          </Col>
        </Row>

        <Row>
          <Col>
            <Image height={350} fluid={images['building']} />
          </Col>

          <Col>
            <HBox height={40} />
            <Heading>
              <Header size="h4" as="h2">
                Здание офиса
              </Header>
            </Heading>
            <HBox />
            <StyledBody>
              Построенное полтора века назад по проекту архитектора Сергея
              Баранкеева, эклектичное сооружение на улице Марата было в своё
              время бывшим домом барона А. Ф. Веймарна и купца Ф. А. Алфёрова.
            </StyledBody>
            <StyledBody>
              С 2021 года этот дом стал новой локацией команды KODE в Питере.
            </StyledBody>
            <HBox height={24} />
          </Col>
        </Row>

        <Row>
          <Col>
            <HBox height={54} />
            <Heading>
              <Header size="h4" as="h2">
                Настолько любим Петербург, что однажды разработали умный гид по
                городу
              </Header>
            </Heading>
            <HBox />
            <StyledBody>
              BEST.Petersburg советует места, события и маршруты, учитывая
              предпочтения пользователя. В дизайне приложения отразили
              идентичность города — многоугольную геометрию колодцев и охру
              питерских стен.
            </StyledBody>
            <HBox height={40} />
          </Col>

          <Col>
            <a target="_blank" href="https://kode.ru/projects/best-petersburg">
              <Image height={350} fluid={images['gide']} />
            </a>
          </Col>
        </Row>

        <HeroDesktopSecondary
          contentPosition="top-right"
          background={images['kaliningrad']}
          adornment={false}
          height={529}
          contentWidth={56.7}
        >
          <HBox height={40} />
          <Heading>
            <Header size="h4" as="h2">
              Командировки в Калининград
            </Header>
          </Heading>
          <HBox />
          <StyledBody
            style={{
              maxWidth: '734px',
            }}
          >
            KODE активно поддерживает связь между городами
            присутствия.&nbsp;В&nbsp;командировках&nbsp;сотрудники собираются
            всей командой&nbsp;офлайн, обсуждают новые проекты, устраивают
            брейнштормы&nbsp;и&nbsp;участвуют в процессе разработки in real
            time.
          </StyledBody>
          <StyledBody>
            Идеальная смена обстановки для продуктивной работы,
            размещение&nbsp;в&nbsp;корпоративной квартире и возможность
            посмотреть&nbsp;город,&nbsp;где работает основная часть команды
            KODE.
          </StyledBody>
          <StyledBody>
            <Link to="/work">Ещё больше о команде KODE →</Link>
          </StyledBody>
        </HeroDesktopSecondary>
      </Wrapper>
      <HBox height={74} />
    </>
  )
}
