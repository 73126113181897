import React from 'react'
import { Link } from 'gatsby'

import { HBox } from 'src/ui/layout'
import { Hero } from 'src/ui/organisms'
import { Body } from 'src/ui/typography'
import { styled } from 'src/ui/theme'

const StyledBody = styled(Body)`
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`

type Props = {
  images: TImages
}

export const Reasons = ({ images }: Props) => (
  <>
    <Hero
      background={images['center']}
      title="В самом центре культурного наследия"
      titleSize="h5"
      textHeight={224}
      objectPosition="bottom center"
    >
      <HBox height={24} />
      <StyledBody>
        В окружении Фонтанки, Невского проспекта и главных туристических
        локаций, мы основали офис KODE в самом центре Петербурга — на улице
        Марата. Во время перерыва можно насладиться неспешной прогулкой до Невы
        и обратно, а вечером — удобно добраться до дома на метро. В шаговой
        доступности три ветки — Маяковская, Достоевская и Владимирская.
      </StyledBody>
    </Hero>
    <HBox height={48} />
    <Hero
      background={images['tecnology']}
      title="Единые технологические подходы"
      titleSize="h5"
      textHeight={224}
    >
      <HBox height={24} />
      <StyledBody>
        Сохранили принципы работы, которые выстраиваем в Калининграде, и
        перевезли накопленный опыт в питерский офис.
      </StyledBody>
      <StyledBody>
        Передовые технологии и подходы, highload-проекты с миллионными DAU и
        высокое качество разработки — ключевые преимущества KODE во всех
        городах. Развиваем командные и индивидуальные инициативы.
      </StyledBody>
    </Hero>
    <HBox height={48} />
    <Hero
      background={images['culture']}
      title="Развитая корпоративная культура"
      titleSize="h5"
      textHeight={224}
    >
      <HBox height={24} />
      <StyledBody>
        Вне работы проводим внутренние митапы для развития скиллов, устраиваем
        тимбилдинги для сплочения команды, а по четвергам играем в настолки, что
        уже стало любимой традицией.
      </StyledBody>
      <StyledBody>
        Дважды в год собираемся большой командой KODE и отправляемся в
        совместное путешествие.
      </StyledBody>
    </Hero>
    <HBox height={48} />
    <Hero
      background={images['space']}
      title="Комфортное лофт-пространство"
      titleSize="h5"
      textHeight={224}
    >
      <HBox height={24} />
      <StyledBody>
        Просторный опенспейс с игровыми креслами, большими мониторами и
        кофе-поинтами. Как говорят сами сотрудники, работать здесь намного
        удобнее, чем дома. К тому же офис открыт в любое время.
      </StyledBody>
    </Hero>
    <HBox height={48} />
    <Hero
      background={images['building']}
      title="Здание офиса"
      titleSize="h5"
      textHeight={260}
    >
      <HBox />
      <StyledBody>
        Построенное полтора века назад по проекту архитектора Сергея Баранкеева,
        эклектичное сооружение на улице Марата было в своё время бывшим домом
        барона А. Ф. Веймарна и купца Ф. А. Алфёрова.
      </StyledBody>
      <StyledBody>
        С 2021 года этот дом стал новой локацией команды KODE в Питере.
      </StyledBody>
    </Hero>
    <HBox height={48} />
    <Hero
      background={images['gide']}
      title="Настолько любим Петербург, что однажды разработали
                умный гид по городу"
      titleSize="h5"
      textHeight={260}
      pictureLink="https://kode.ru/projects/best-petersburg"
      objectFit="contain"
    >
      <HBox />
      <StyledBody>
        BEST.Petersburg советует места, события и маршруты, учитывая
        предпочтения пользователя. В дизайне приложения отразили идентичность
        города — многоугольную геометрию колодцев и охру питерских стен.
      </StyledBody>
    </Hero>
    <HBox height={48} />
    <Hero
      background={images['kaliningrad']}
      title="Командировки в Калининград"
      titleSize="h5"
      textHeight={260}
    >
      <HBox />
      <StyledBody>
        KODE активно поддерживает связь между городами присутствия. В
        командировках сотрудники собираются всей командой офлайн, обсуждают
        новые проекты, устраивают брейнштормы и участвуют в процессе разработки
        in real time.
      </StyledBody>
      <StyledBody>
        Идеальная смена обстановки для продуктивной работы, размещение в
        корпоративной квартире и возможность посмотреть город, где работает
        основная часть команды KODE.
      </StyledBody>
      <StyledBody>
        <Link to="/work">Ещё больше о команде KODE →</Link>
      </StyledBody>
    </Hero>
  </>
)
